import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store, { Persister } from './components/Store';
import { PersistGate } from 'redux-persist/integration/react'; 
import { UserProperties } from './commonComponents/Common/webProperties';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={Persister}>
        
          <App />
      
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you still want to use serviceWorker, keep this
serviceWorker.unregister();
