import React, { useEffect, useReducer, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@mui/material';
import { Backdrop, Fade } from '@mui/material';
import { useSelector } from 'react-redux';
import { chatReducer, initialState } from './reducer';
import { updateChat } from './Services/updateChat';
import { getMessages } from './Services/getMessages';
import { getGroupMembersList } from './Services/getGroupMembersList';
import { getGroupMembersEmail } from './Services/getGroupMembersEmail';
import * as actions from './actions';
import API from '../Network/API';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
// import RootLoader from '../Loader/RootLoad';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import "../vendor/doka.min.css";
import { create } from "../vendor/doka.esm.min";
import convertPSTtoLocalTime from '../convertPSTtoLocalTime';
import { useWebProperties } from '../webProperties';
import { FiPaperclip } from "react-icons/fi"; // Attach File icon
import { FaVideo } from "react-icons/fa"; // Video Call icon
import { getSubStringId } from '../SubStringConvert';
// import JitsiComponent from '../Jitsi';
import { Resizable } from 're-resizable';
import Draggable from 'react-draggable';
import { isMobile } from 'react-device-detect';
import { Meet_URL, Server_URL } from '../config';
import { removeSpecialCharacters } from '../commonUsage';
import axios from 'axios';
import { Element, scroller } from "react-scroll";
import Highlighter from "react-highlight-words";
import { logEntry } from '../logEntry';
import { FiSend } from "react-icons/fi";
import { SignJWT } from "jose";
// Register the plugins
registerPlugin(FilePondPluginImageEdit, FilePondPluginImagePreview, FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateType, FilePondPluginImageResize, FilePondPluginImageTransform)


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%', // Adjust as needed, or use '100%' for full width
        // maxWidth: 600, // Set a maximum width if needed
        // padding: theme.spacing(3), // Adjust spacing as needed
    },
}));


export default function MainTaskChatBox(props) {

    const nodeRef = useRef(null);
    const classNames = useStyles();
    const getUser = useSelector(state => state.auth)

    const [state, dispatch] = useReducer(chatReducer, initialState)
    const CHATAPI = `${Server_URL}`;
    const MAINTASK = "Task";
    const ATTACH_FILE = "Attach";
    const role_array = ["Contributor", "User", "Guest"];
    const LIMITED_ACCESS_CONTRIBUTOR = "Limited Access Contributor";

    const [chat, setChat] = useState(0);
    const [copyfile, setCopyFile] = useState({});
    const [docFile, setDocFile] = useState({});
    const [commentDetails, setCommentDetails] = useState({});
    const [handleHover, setHandleHover] = useState(false);
    const [disable, setDisable] = useState(false);
    const [taskMeetingUrl, setTaskMeetingUrl] = useState(null)
    const [groupTask, setGroupTask] = useState([])
   
    const project_name = '791d329455b176c6afca53ff2deb9c4d';
    const [showAllNames, setShowAllNames] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)

    console.log("Modal open state:", props);

    const [searchTerm, setSearchTerm] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [highlightedMessageId, setHighlightedMessageId] = useState(null);
    const [chatActive, setchatActive] = useState(false);
    const [showList, setShowList] = useState(false);
    const [groupTaskMembers, setGroupTaskMembers] = useState([])
    const [employees, setEmployees] = useState([]);
    const [groupTaskList, setGroupTaskList] = useState([])
    const [infoUsers, setInfoUsers] = useState([]);
    const [infoOpen, setInfoOpen] = useState(false)
    const [infoMessageId, setInfoMessageId] = useState(null)
    const [empId, setEmpId] = useState(null);
    const [messages, setMessages] = useState([])

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            setShowResults(true);
            setchatActive(true);
        }
    };
    const filteredChatData = messages.filter((item) =>
        item.message.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // console.log("filtered data ::",filteredChatData);

    const clearSearch = () => {
        setSearchTerm('');
        setShowResults(false);

    };


    const handleChat = () => {
        setChat(1)
    }
    const handleChatClose = () => {
        setChat(0)
        setHideButton(false)
    }
    useEffect(() => {
        if (props.data.meetingUrl !== null && props.data.meetingUrl !== undefined) {
            setTaskMeetingUrl(props.data.meetingUrl)
        }else {
            updateTaskMeetingUrl(props.data.id)
        }
        getMessages(props.data);

        // eslint-disable-next-line
    }, [])

    async function getMessages(data) {
        try {
            const response = await axios.post(
                `${Server_URL}/user_story_chat.php`,
                {
                    corp_code: "KShiksha",
                    action: "getmessages",
                    groupId: data.id
                }
            );

            console.log("getmessages console", response.data.data);

            if (response.status === 200 && response.data.status === "True") {

                setMessages(response.data.data)
            } else {
                return [];
            }
        } catch (error) {
            console.error("Error fetching messages:", error.message);
            return [];
        }
    }
    useEffect(() => {
        const fetchMessages = () => {
            getMessages(props.data);
            setTimeout(fetchMessages, 15000);
        };

        fetchMessages();

        // Cleanup function to stop fetching messages on unmount
        return () => clearTimeout(fetchMessages);
        // eslint-disable-next-line
    }, []);


    const updateTaskMeetingUrl = async (us_id) => {
        const secretKey = new TextEncoder().encode("RALLYWETASKER"); // Convert to Uint8Array
    
        const payload = {
            context: {
                user: {
                    avatar: "",
                    name: "",
                    email: "",
                },
            },
            aud: "jitsi",
            iss: "23456789",
            sub: Meet_URL,
            room: removeSpecialCharacters(us_id),
        };
    
        // Generate JWT using `jose`
        const generatedToken = await new SignJWT(payload)
            .setProtectedHeader({ alg: "HS256" })
            .sign(secretKey);
    
        const meeting_url = `https://${Meet_URL}/${removeSpecialCharacters(us_id)}?jwt=${generatedToken}#jitsi_meet_external_api_id=98765432&config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.autoCaptionOnRecord=true`;
    
        try {
            const response = await axios.post(
                `${Server_URL}/manage_userstories.php`,
                {
                    action: "updateMeetingURL",
                    us_id: us_id,
                    meeting_url: meeting_url,
                }
            );
    
            if (response.data.status === "True") {
                setTaskMeetingUrl(response.data.meeting_url);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const msgId = props.data.sno ? props.data.sno.map((msgId, index) => {
        return msgId.sno
    }) : null

    const modalBodyRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            scrollToBottom();
        }, 1000)
    }, []);

    const scrollToBottom = () => {
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
        }
    };

    const sendTaskMeetingUrl = () => {
        window.open(taskMeetingUrl, "_blank")
        let message = `${props.data.fullName || ''} Started Meeting, to connect <a target="_blank" href='${taskMeetingUrl}'>Click Here</a>`
        // eslint-disable-next-line
        sendMeetUrl(message)
        // eslint-disable-next-line
    }

    const sendMeetUrl = async (message) => {
        
        let taskEmails = [];

        let allEmails = [...taskEmails];
        let uniqueEmails = [...new Set(allEmails)];

        state.taskComments.push({
            "groupId": props.data.id,
            "message": message,
            "messagedBy": props.data.empId || props.data.messagedBy,
            "messagedTime": "",
            "reply_message": null,
            "reply_path": null,
            "reply_username": null,
            "path": null,
            "username": props.data.fullName,
            sub_group_ids: [...new Set(groupTask)]
        });

        dispatch(actions.comment(""));

        try {
            const start_time = Date.now()
            // eslint-disable-next-line
            var response = await axios.post(
                `${Server_URL}/user_story_chat.php`, {
                action: "send",
                corp_code: props.data.project_name,
                groupId: props.data.id,
                message: message,
                messagedBy: props.data.empId || props.data.messagedBy,
                msgId: msgId ? msgId : " ",
                groupName: `${getSubStringId(props.data.project_name, 3)}-${props.data.id}-${props.data.taskTitle}`,
                groupEmail: uniqueEmails,
                projectId: props.data.project_id,
                reply_id: commentDetails.id ? commentDetails.id : "",
                sub_group_ids: [...new Set(groupTask)]
            }, {}, false);
            const end_time = Date.now()
            getMessages(dispatch, props.data);
            setEmployees([])
            setDisable(false)
          
        } catch (error) {
            setErrorMessage("Failed to send the message")
           
        }
    }


    useEffect(() => {

        //checking it is a link
        //renderMessageContent(state.comment);
        if (state.comment.trim() !== "") {
            setErrorMessage(null);
            //console.log('UseEffect State comment',state.comment);
        }
    }, [state.comment])

    const sendMessage = async (message = null) => {
        let otherEmails = [];
        let commentInfo = commentDetails
        setCommentDetails({})
        //console.log('state.message:',state.comment);

        let taskEmails = [];
        if (state.groupList.data && state.groupList.data.length > 0) {
            const firstDataItem = state.groupList.data[0];
            taskEmails.push(firstDataItem.assigned_to_email || '');
            taskEmails.push(firstDataItem.assigned_by_email || '');
        }

        // Combine the two arrays and remove duplicates
        //console.log(message)
        let allEmails = [...taskEmails, ...otherEmails];
        let uniqueEmails = [...new Set(allEmails)];

        if (state.comment.trim() !== "" || message !== null) {
            setErrorMessage(null)
            setDisable(true)
            // dispatch(isLoading());
            state.taskComments.push({
                "groupId": props.data.id,
                "message": state.comment.trim() !== "" ? state.comment : message,
                "messagedBy": props.data.messagedBy,
                "messagedTime": "",
                "reply_message": null,
                "reply_path": null,
                "reply_username": null,
                "username": props.data.fullName,
                sub_group_ids: [...new Set(groupTask)],
                "isLoading": true
            });

            let comment = message ? message : state.comment;
            state.comment = '';
            function isLink(str) {
                // Regular expression to match URLs
                const urlRegex = /(https?:\/\/[^\s]+)/;
                return urlRegex.test(str);
            }

            // Example usage:
            const text = comment;
            let test = comment;
            // Check if the comment contains any new links
            if (isLink(text)) {
                // Find all existing hyperlinks in the comment and wrap them with anchor tags
                test = test.replace(/(<a href="([^"]+)">([^<]+)<\/a>)/g, '$2');

                // Replace any new links with anchor tags
                test = test.replace(/(https?:\/\/[^\s]+)/g, "<a href='$1' target='_blank'>$1</a>");
            }
            setHandleHover(false);

            try {
                const start_time = Date.now()
                // eslint-disable-next-line
                var response = await axios.post(
                    `${Server_URL}/user_story_chat.php`, {
                    action: "send",
                    corp_code: project_name,
                    groupId: props.data.id,
                    message: test,
                    //" <a href='https://www.flipkart.com/' target='_blank'>Test Link</a>"
                    messagedBy: props.data.empId || props.data.messagedBy,
                    msgId: msgId ? msgId : " ",
                    groupName: `${getSubStringId(project_name, 3)}-${props.data.id}-${props.data.taskTitle}`,
                    groupEmail: uniqueEmails,
                    projectId: props.data.project_id,
                    reply_id: commentInfo.id ? commentInfo.id : "",
                    sub_group_ids: [...new Set(groupTask)]
                }, {}, false);
                const end_time = Date.now()
                getMessages(props.data);
                setDisable(false);
                setHandleHover(false);
                setCommentDetails({});
                setEmployees([])
                test = '';


            } catch (error) {
                //  console.log("error", error)
                setDisable(false)
                setErrorMessage("Failed to send the message")

            }
        }


        else {
            setDisable(false)
            setHideButton(false)
            setErrorMessage("Please type the message");
        }
    }
    const fileHandle = async (file) => {
        let commentInfo = commentDetails
        setCommentDetails({});


        let taskEmails = [];
        if (state.groupList.data && state.groupList.data.length > 0) {
            const firstDataItem = state.groupList.data[0];
            taskEmails.push(firstDataItem.assigned_to_email || '');
            taskEmails.push(firstDataItem.assigned_by_email || '');
        }

        // Combine the two arrays and remove duplicates
        let allEmails = [...taskEmails];
        let uniqueEmails = [...new Set(allEmails)];


        if (file !== "" && file.name !== undefined) {
            setDisable(true)
            const data = new FormData();
            data.append('file', file);
            data.append('message', file.name);
            // required data for send message
            data.append('action', 'media');
            data.append('corp_code', project_name);
            data.append('groupId', props.data.id);
            data.append('messagedBy', props.data.empId || props.data.messagedBy);
            data.append('api', CHATAPI);
            data.append('groupName', `${getSubStringId(project_name, 3)}-${props.data.id}-${props.data.taskTitle}`);
            data.append('groupEmail', uniqueEmails);
            data.append('reply_id', commentInfo.id ? commentInfo.id : "");
            [...new Set(groupTask)].forEach((task, index) => {
                data.append(`sub_group_ids[${index}]`, task);
            });
            try {
                const start_time = Date.now()
                await axios.post(
                    `${Server_URL}/user_story_chat1.php`, data
                    , {}, false);
                const end_time = Date.now()
                getMessages(props.data);
                dispatch(actions.comment(""));
                setChat(0)
                setDocFile({})
                setDisable(false)
                setHandleHover(false);
                setCommentDetails({});
                setHideButton(false)
                setEmployees([])

            } catch (error) {
                console.log(error)
                setDisable(false)
                setHideButton(false)

            }
            setDisable(false)
        } else {
            setDisable(false)
            handleChatClose();
            sendMessage();
        }
    }

    const myPaste = (e) => {
        window.addEventListener('paste', e => {
            setCopyFile(e.clipboardData.files[0])
            if (e.clipboardData.files.length !== 0) {
                setChat(2)
            }
        });

    }

    const handleDoc = (file) => {
        const fileData = file.file
        // console.log("file",fileData)
        setDocFile(fileData)
    }

    var textInput = useRef(null);
    // for handling reply 
    const handlecom = (comment) => {
        setInfoOpen(false)
        setCommentDetails(comment);
        setHandleHover(true);
        if (textInput) textInput.current.focus();
    }

    const handleMouseOut = () => {
        setHandleHover(false);
        setCommentDetails({})
    };

    const [size, setSize] = useState({ width: isMobile ? '90%' : 700, height: 500 });


    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.which === 13) {
            e.preventDefault();
            if (state.comment.trim() === '') {
                setErrorMessage("Please type the message");
            }
            else {
                if (state.comment.trim() !== "") {
                    handleChatClose();
                    sendMessage();

                } else {
                    fileHandle(docFile)
                }
            }
        }
    };

    const [hideButtons, setHideButton] = useState(false)

    const handleToggleNames = () => {
        setShowAllNames(!showAllNames);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const position = e.target.selectionStart;
        dispatch(actions.comment(value));

        // Find the last occurrence of '@' before the cursor position
        const atIndex = value.lastIndexOf('@', position - 1);
        if (atIndex !== -1) {
            const textAfterAt = value.substring(atIndex, position);
            const shouldShowList = textAfterAt;
            if (shouldShowList === '@') {
                setShowList(shouldShowList);
            } else {
                setShowList(false);
            }
        } else {
            setShowList(false);
        }
    };

    const handleItemClick = (item) => {

        let inputValue = state.comment
        const atIndex = inputValue.lastIndexOf('@');

        // Replace text after '@' with the selected item
        const newValue =
            inputValue.substring(0, atIndex + 1) +
            item.full_name +
            ' ';

        dispatch(actions.comment(newValue));
        setShowList(false);

        // Ensure the cursor is placed correctly after the update

        setTimeout(() => {
            const newPosition = newValue.length;
            textInput.current.setSelectionRange(newPosition, newPosition);
            textInput.current.focus();
        }, 0);
    };

    const handleDelete = async (comments) => {
        setInfoOpen(false)
        try {
            const start_time = Date.now()
            var response = await axios.post(
                `${Server_URL}/user_story_chat.php`, {
                action: "messageDelete",
                messageId: comments.id,
                sub_group_ids: groupTask,
                deleteMessage: comments.message,
                deleteMessageTime: comments.messagedTime
            }, {}, false);
            const end_time = Date.now()
            if (response.status === "True") {
                getMessages(props.data);


            }
        } catch (error) {
            console.log(error)


        }
    }

    const addEmployee = (newEmployee) => {
        setEmployees((prevEmployees) => {
            // Check if the employee with the same emp_id already exists
            const exists = prevEmployees.some(
                (employee) => employee.emp_id === newEmployee.emp_id
            );

            // If not exists, push the new employee to the array
            if (!exists) {
                return [...prevEmployees, newEmployee];
            }

            // Otherwise, return the original array without modification
            return prevEmployees;
        });
    };

    useEffect(() => {
        if (employees.length > 0 && props.data.group_task_type === 'Full') {
            setGroupTask(groupTaskList?.filter(employee =>
                employees?.some(task => task.emp_id === employee.assigned_to)
            ).map(item => { return item.us_id }))
        }
        // eslint-disable-next-line
    }, [employees])

    const handleGetInfo = async (comments) => {
        setInfoOpen(false)
        setInfoMessageId(null)
        try {
            var response = await axios.post(
                `${Server_URL}/group_chat.php`, {
                action: "getUserInfo",
                ready_by: comments.read_by
            }, {}, false);
            if (response.status === "True") {
                setInfoUsers(response.data.filter(item => {
                    return item.emp_id !== props.data.empId
                }))
                setInfoOpen(true)
                setInfoMessageId(comments.id)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleInfoClose = () => {
        setInfoOpen(false)
    }
    const handleClick = (id) => {
        setHighlightedMessageId(id);
        scroller.scrollTo(`message-${id}`, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };
    console.log(hideButtons, 'button')
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={props.handleClose}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <div className={classNames.paper} style={{ padding: '10px', borderRadius: '8px', backgroundColor: '#fff', height: '90%', paddingRight: '30px' }}>

                    <div className="modal-content p-2 tsk" style={{ borderRadius: '10px', height: '90%', position: 'absolute', }}>
                        <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '2px solid #e0e0e0', paddingBottom: '10px', backgroundColor: '#ccc' }}>
                            <h2 style={{ fontSize: '1.25rem', fontWeight: '600', color: '#333', marginLeft: '5%' }}>
                                {props.data.id}{" "}{props.data.taskTitle}
                            </h2>
                            <button
                                style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                    fontSize: "24px", // Adjust size if needed
                                    color: "#333",
                                    marginRight: "5%"
                                }}
                                onClick={props.handleModalClose}
                            >
                                ✖
                            </button>

                        </div>

                        <div className="modal-body" style={{ paddingTop: '15px', paddingBottom: '15px', fontSize: '1rem', color: '#666', paddingRight: '10px' }}>

                            {/* <div style={{ marginTop: '20px' }}>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                style={{
                                    paddingRight: '30px',
                                    marginBottom: '10px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                }}
                                placeholder="Search here"
                                aria-label="Search"
                                aria-describedby="button-addon2"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyPress={handleKeyPress}
                                onDoubleClick={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseUp={(e) => e.stopPropagation()}
                            />
                            {searchTerm && (
                                <button
                                    onClick={clearSearch}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '40%',
                                        transform: 'translateY(-50%)',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '0',
                                        outline: 'none',
                                        fontSize: '16px',
                                    }}
                                >

                                </button>
                            )}
                        </div> */}
                            <div className="modal-body overflow-md-scroll overflow-auto" style={{ height: isMobile ? '50%' : '60%' }} ref={modalBodyRef}>
                                <div className="" style={{ display: 'flex', flexDirection: 'column-reverse' }}>

                                    {chatActive && showResults && filteredChatData.length > 0
                                        ? filteredChatData.map((item, index) => (
                                            <>
                                                <Element
                                                    name={`message-${item.id}`}
                                                    key={item.id}
                                                    onClick={() => handleClick(item.id)}
                                                    style={{

                                                        marginBottom: "5px",
                                                        backgroundColor:
                                                            highlightedMessageId === item.id ? "#e1e1e1" : "#fff",
                                                        border:
                                                            highlightedMessageId === item.id,


                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <q style={{ color: 'grey', fontSize: "8px" }}>{convertPSTtoLocalTime(item.messagedTime)}</q>

                                                    <div className='flex ' style={{ display: 'flex' }}>
                                                        <div style={{ fontWeight: "bold", color: 'green' }}>
                                                            {item.username} :
                                                        </div>
                                                        <Highlighter
                                                            highlightClassName="YourHighlightClass"
                                                            searchWords={[searchTerm]}
                                                            autoEscape={true}
                                                            //   style={{color:"yellow"}}
                                                            textToHighlight={item.message}
                                                        />
                                                    </div>
                                                    {/* </div> */}

                                                </Element>
                                                {index < filteredChatData.length - 1 && <hr />}
                                            </>


                                        ))
                                        : showResults && <div>No results found</div>}


                                    {!showResults && messages !== '' ? messages.slice(0).reverse().map((comments, index) => {

                                        return (
                                            <div key={index}>
                                                {
                                                    comments.messagedBy !== (props.data.empId) ?
                                                        <p className="text-left pr-3"  >
                                                            <p className="pt-2" style={{ color: 'green' }}>{comments.username}<q style={{ color: 'grey', fontSize: "8px" }}>{convertPSTtoLocalTime(comments.messagedTime)}</q></p>
                                                            {comments.path ?
                                                                <div style={{ height: "67%", backgroundColor: "#f3f3f3", float: "left", borderRadius: '7px' }}>

                                                                    {/* for image with reply content and dropdown left side content */}
                                                                    {comments.reply_message !== null ?
                                                                        <li className="dropdown show" style={{ listStyle: "none", float: "right" }}>
                                                                            {
                                                                                comments.is_deleted === "true" ?
                                                                                    null
                                                                                    :
                                                                                    <div style={{ backgroundColor: "#D2ECDF", borderRadius: "7px", padding: "10px" }}>
                                                                                        <p style={{ color: "green", }}>{comments.reply_username}</p>
                                                                                        <p style={{ display: "inline-block", textAlign: 'left', width: "100%", whiteSpace: "normal", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: "5px" }}>
                                                                                            {comments.reply_path ?
                                                                                                comments.reply_path.substr(comments.reply_path.length - 4) === "blob" && comments.reply_message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                    // eslint-disable-next-line 
                                                                                                    <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                        <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a> :
                                                                                                    !comments.reply_path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                        //  eslint-disable-next-line  
                                                                                                        <a download={comments.reply_message} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                            {comments.reply_message}</a> :
                                                                                                        //  eslint-disable-next-line  
                                                                                                        <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                            <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a>
                                                                                                :
                                                                                                <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.reply_message }}></div>
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                            }
                                                                            {/* {
                                                                                comments.is_deleted === "true" ?
                                                                                    null
                                                                                    :
                                                                                    <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '10px', float: 'right', marginTop: '-8px', visibility: comments.isLoading ? 'hidden' : 'visible' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    </a>
                                                                            } */}
                                                                            {/* drop-down icon and reply button */}
                                                                            <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                {/* eslint-disable-next-line   */}
                                                                                <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                            </div>
                                                                            {/* End of drop-down icon and reply button */}

                                                                            <p style={{ backgroundColor: "#f3f3f3", width: '100%', textAlign: 'right', padding: "12px 10px 12px 14px", borderRadius: "7px" }}>
                                                                                {/* {comments.message} */}
                                                                                {comments.is_deleted === "true" ? <div style={{ whiteSpace: 'break-spaces', color: 'grey' }} dangerouslySetInnerHTML={{ __html: `${comments.username} deleted this message` }}></div>
                                                                                    :
                                                                                    comments.path.substr(comments.path.length - 4) === "blob" && comments.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                        //  eslint-disable-next-line
                                                                                        <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                            <img style={{ marginTop: '-12px', marginRight: '12px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                        :
                                                                                        !comments.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                            //  eslint-disable-next-line
                                                                                            <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                                                                            :
                                                                                            //  eslint-disable-next-line 
                                                                                            <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                <img style={{ marginTop: '-14px', marginRight: '14px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                }
                                                                                {/* eslint-disable-next-line  */}

                                                                            </p>
                                                                        </li>

                                                                        :
                                                                        // for image with left side dropdown and image only
                                                                        <li className="dropdown show" style={{ listStyle: "none" }}>
                                                                            {/* {
                                                                                comments.is_deleted === "true" ?
                                                                                    null
                                                                                    :
                                                                                    <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '0px', float: 'right', visibility: comments.isLoading ? 'hidden' : 'visible' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    </a>
                                                                            } */}
                                                                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                {/* eslint-disable-next-line */}
                                                                                <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                            </div>
                                                                            <p style={{ backgroundColor: "#f3f3f3", padding: "12px 10px 12px 14px", borderRadius: "7px", marginTop: '4px', color: comments.is_deleted === "true" ? 'grey' : 'black' }}>
                                                                                {
                                                                                    comments.is_deleted === "true" ?
                                                                                        `${comments.username} deleted this message`
                                                                                        :
                                                                                        comments.path.substr(comments.path.length - 4) === "blob" && comments.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                            //   eslint-disable-next-line 
                                                                                            <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                <img style={{ marginTop: '-8px', marginRight: '12px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                            :
                                                                                            !comments.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                //    eslint-disable-next-line 
                                                                                                <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                                                                                :
                                                                                                //  eslint-disable-next-line 
                                                                                                <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                    <img style={{ marginTop: '-8px', marginRight: '12px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                }
                                                                                {/* eslint-disable-next-line */}

                                                                            </p>
                                                                        </li>
                                                                    }</div>
                                                                :
                                                                //for message display reply content and dropdown left side reply messages 
                                                                comments.reply_message !== null ?
                                                                    <li className="dropdown show" style={{ listStyle: "none", float: "left" }}>
                                                                        {
                                                                            comments.is_deleted === "true" ?
                                                                                null
                                                                                :
                                                                                <div style={{ backgroundColor: "#D2ECDF", borderRadius: "7px", width: '100%', textAlign: 'left', padding: "12px 10px 12px 14px" }}>
                                                                                    <p style={{ color: "green", }}>{comments.reply_username}</p>
                                                                                    <p style={{ display: "inline-block", whiteSpace: "normal", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: "12px" }}>
                                                                                        {comments.reply_path ?
                                                                                            comments.reply_path.substr(comments.reply_path.length - 4) === "blob" && comments.reply_message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                // eslint-disable-next-line 
                                                                                                <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                    <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a> :
                                                                                                !comments.reply_path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                    //  eslint-disable-next-line  
                                                                                                    <a download={comments.reply_message} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                        {comments.reply_message}</a> :
                                                                                                    //  eslint-disable-next-line  
                                                                                                    <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                        <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a>
                                                                                            :
                                                                                            <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.reply_message }}></div>
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                        }
                                                                        {/* {
                                                                            comments.is_deleted === "true" ?
                                                                                null
                                                                                :
                                                                                <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '10px', float: 'right', marginTop: '-8px', visibility: comments.isLoading ? 'hidden' : 'visible' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                </a>
                                                                        } */}
                                                                        <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                            {/* eslint-disable-next-line   */}
                                                                            <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                        </div>
                                                                        <p style={{ backgroundColor: "#f3f3f3", width: '100%', textAlign: 'left', padding: "12px 10px 12px 14px", borderRadius: "7px", marginTop: '-8px' }}>
                                                                            <div style={{ whiteSpace: 'break-spaces', color: comments.is_deleted === "true" ? 'grey' : 'black' }} dangerouslySetInnerHTML={{ __html: comments.is_deleted === 'true' ? `${comments.username} deleted this message` : comments.message }}></div>
                                                                            {/* eslint-disable-next-line  */}
                                                                        </p>
                                                                    </li>
                                                                    :
                                                                    // for message display dropdown left side of chat box messages
                                                                    <li className="dropdown show" style={{ listStyle: "none" }}>
                                                                        <div className="" style={{
                                                                            margin: '0px', display: 'flex',
                                                                            alignItems: 'normal',
                                                                            justifyContent: 'start'
                                                                        }}>
                                                                            <div className="" style={{ maxWidth: 'fit-content', padding: '0px' }}>
                                                                                <p style={{ backgroundColor: "#f3f3f3", width: 'fit-content', textAlign: 'left', padding: "12px 10px 12px 14px", borderRadius: "7px 0px 0px 7px", paddingTop: '12px', paddingLeft: '14px' }}>
                                                                                    <div style={{ whiteSpace: 'break-spaces', color: comments.is_deleted === "true" ? 'grey' : 'black' }} dangerouslySetInnerHTML={{ __html: comments.is_deleted === 'true' ? `${comments.username} deleted this message` : comments.message }}></div>
                                                                                </p>
                                                                            </div>
                                                                            <div className="" style={{ backgroundColor: "#f3f3f3", marginBottom: '4.5px', padding: '0px', borderRadius: "0px 7px 7px 0px" }}>
                                                                                {/* {
                                                                                    comments.is_deleted === "true" ?
                                                                                        null
                                                                                        :
                                                                                        <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '0px', marginTop: '12px', visibility: comments.isLoading ? 'hidden' : 'visible' }} className="btn dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        </a>
                                                                                } */}
                                                                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                    {/* eslint-disable-next-line   */}
                                                                                    <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <p style={{backgroundColor:"#f3f3f3", width:'fit-content',textAlign: 'left', padding: "12px 10px 12px 14px", borderRadius:"7px" , paddingTop:'12px',paddingLeft:'14px',wordBreak:'break-all'}}>
                                                                            {comments.message} </p> */}
                                                                        {/* eslint-disable-next-line  */}


                                                                    </li>}</p> :
                                                        //user messages     user message    user  messages
                                                        <p className="text-right pr-3" style={{ minWidth: "fit-content" }} ><p className="pt-2" style={{ color: 'green', minWidth: "fit-content", marginLeft: isMobile ? '10%' : '75%' }}>{comments.username}&nbsp;<q style={{ color: 'grey', fontSize: "8px" }}>{convertPSTtoLocalTime(comments.messagedTime)}</q></p>
                                                            {comments.path ?
                                                                <div style={{ height: "67%", backgroundColor: "#f3f3f3", padding: '0px', float: "right" }}>

                                                                    {/* for image with reply content and dropdown for right side chat    */}
                                                                    {comments.reply_message !== null ?
                                                                        <li className="dropdown show" style={{ listStyle: "none", float: "right" }}>
                                                                            {
                                                                                comments.is_deleted === 'true' ?
                                                                                    null
                                                                                    :
                                                                                    <div style={{ backgroundColor: "#D2ECDF", borderRadius: "7px", padding: "10px" }}>
                                                                                        <p style={{ color: "green", }}>{comments.reply_username}</p>
                                                                                        <p style={{ display: "inline-block", textAlign: 'right', width: "100%", whiteSpace: "normal", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: "5px" }}>
                                                                                            {comments.reply_path ?
                                                                                                comments.reply_path.substr(comments.reply_path.length - 4) === "blob" && comments.reply_message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                    // eslint-disable-next-line 
                                                                                                    <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                        <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a> :
                                                                                                    !comments.reply_path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                        //  eslint-disable-next-line  
                                                                                                        <a download={comments.reply_message} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                            {comments.reply_message}</a> :
                                                                                                        //  eslint-disable-next-line  
                                                                                                        <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                            <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a>
                                                                                                :
                                                                                                <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.reply_message }}></div>

                                                                                            }
                                                                                        </p></div>
                                                                            }
                                                                            {/* {
                                                                                comments.is_deleted === "true" ?
                                                                                    null
                                                                                    :
                                                                                    <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '10px', float: 'right', marginTop: '-8px', visibility: comments.isLoading ? 'hidden' : 'visible' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    </a>
                                                                            } */}
                                                                            <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                {/* eslint-disable-next-line   */}
                                                                                <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                {/* eslint-disable-next-line */}
                                                                                <a className="dropdown-item" onClick={() => handleDelete(comments)}>Delete</a>
                                                                            </div>
                                                                            <p style={{ backgroundColor: "#f3f3f3", width: '100%', textAlign: 'right', padding: "12px 10px 12px 14px", borderRadius: "7px" }}>
                                                                                {/* {comments.message} */}
                                                                                {comments.is_deleted === "true" ? <div style={{ whiteSpace: 'break-spaces', color: 'grey' }} dangerouslySetInnerHTML={{ __html: "you deleted this message" }}></div>
                                                                                    :
                                                                                    comments.path.substr(comments.path.length - 4) === "blob" && comments.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                        //  eslint-disable-next-line
                                                                                        <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                            <img style={{ marginTop: '-20px', marginRight: '15px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                        :
                                                                                        !comments.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                            //  eslint-disable-next-line
                                                                                            <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                                                                            :
                                                                                            //  eslint-disable-next-line 
                                                                                            <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                                <img style={{ marginTop: '-20px', marginRight: '15px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                }
                                                                                {/* eslint-disable-next-line  */}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        // for image with right side dropdown and image only
                                                                        <li className="dropdown show" style={{ listStyle: "none" }}>
                                                                            {/* drop-down icon and reply button */}
                                                                            {/* {
                                                                                comments.is_deleted === 'true' ?
                                                                                    null :
                                                                                    <p style={{ borderRadius: '30px', color: "gray", padding: '0px', visibility: comments.isLoading ? 'hidden' : 'visible' }} className="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    </p>
                                                                            } */}
                                                                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                {/* eslint-disable-next-line */}
                                                                                <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                {/* eslint-disable-next-line */}
                                                                                <a className="dropdown-item" onClick={() => handleGetInfo(comments)}>Info</a>
                                                                                {/* eslint-disable-next-line */}
                                                                                <a className="dropdown-item" onClick={() => handleDelete(comments)}>Delete</a>

                                                                            </div>
                                                                            {
                                                                                infoOpen === true && infoMessageId === comments.id ?
                                                                                    <div className="dropdown show" style={{
                                                                                        borderRadius: "7px", minWidth: "fit-content", margin: '10px',
                                                                                        border: '1px solid',
                                                                                        padding: '5px',
                                                                                        width: '250px',
                                                                                        maxHeight: '200px',
                                                                                        overflow: 'auto',
                                                                                        float: 'right'

                                                                                    }}>
                                                                                        <label style={{
                                                                                            color: 'green'
                                                                                        }}><b>Read By</b><label onClick={() => {
                                                                                            handleInfoClose()
                                                                                        }} style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}><b>X</b></label></label>
                                                                                        {
                                                                                            infoUsers?.map((item => {
                                                                                                return <div><label>{item.username}</label><br /></div>
                                                                                            }))
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {/*End of drop-down icon and reply button */}
                                                                            <p style={{ backgroundColor: "#f3f3f3", width: '100%', textAlign: 'left', padding: "12px 10px 12px 14px", borderRadius: "7px", color: comments.is_deleted === "true" ? 'grey' : 'black' }}>

                                                                                {comments.is_deleted === "true" ? "you deleted this message" : comments.path.substr(comments.path.length - 4) === "blob" && comments.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                    //  eslint-disable-next-line
                                                                                    <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                        <img style={{ marginTop: '-25px', marginRight: '15px' }} alt={comments.message} src={comments.path} width="400" /></a>
                                                                                    :
                                                                                    !comments.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                        //  eslint-disable-next-line
                                                                                        <a download={comments.message} className='text-primary' target="_blank" href={comments.path}>{comments.message}</a>
                                                                                        :
                                                                                        //  eslint-disable-next-line 
                                                                                        <a download={comments.path} className='text-primary' target="_blank" href={comments.path}>
                                                                                            <img alt={comments.message} src={comments.path} width="400" /></a>
                                                                                }
                                                                                {/* eslint-disable-next-line */}

                                                                            </p>
                                                                        </li>
                                                                    }</div>
                                                                //for message display reply content and dropdown right side content
                                                                : comments.reply_message !== null ?
                                                                    <li className="dropdown show" style={{ listStyle: "none", float: "right" }}>
                                                                        {
                                                                            comments.is_deleted === "true" ?
                                                                                null
                                                                                :
                                                                                < div style={{ backgroundColor: "#D2ECDF", borderRadius: "7px", width: '100%', textAlign: 'left', padding: '12px 10px 12px 14px' }}>
                                                                                    <p style={{ color: "green", float: "left" }}>{comments.reply_username}</p><br />
                                                                                    <p
                                                                                        style={{ display: "inline-block", width: '100%', textAlign: 'left', whiteSpace: "normal", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: "5px" }}>
                                                                                        {comments.reply_path ?
                                                                                            comments.reply_path.substr(comments.reply_path.length - 4) === "blob" && comments.reply_message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                // eslint-disable-next-line 
                                                                                                <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                    <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a> :
                                                                                                !comments.reply_path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                                                                    //  eslint-disable-next-line  
                                                                                                    <a download={comments.reply_message} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                        {comments.reply_message}</a> :
                                                                                                    //  eslint-disable-next-line  
                                                                                                    <a download={comments.reply_path} className='text-primary' target="_blank" href={comments.reply_path}>
                                                                                                        <img alt={comments.reply_message} src={comments.reply_path} width="60" height="60" /></a>
                                                                                            :
                                                                                            <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: comments.reply_message }}></div>
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                        }
                                                                        {/* {
                                                                            comments.is_deleted === "true" ?
                                                                                null
                                                                                :
                                                                                <a href="/" style={{ borderRadius: '30px', color: "gray", padding: '10px', float: 'right', marginTop: '-8px', visibility: comments.isLoading ? 'hidden' : 'visible' }} className="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                </a>
                                                                        } */}
                                                                        <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                            {/* eslint-disable-next-line   */}
                                                                            <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                            {/* eslint-disable-next-line */}
                                                                            <a className="dropdown-item" onClick={() => handleGetInfo(comments)}>Info</a>
                                                                            {/* eslint-disable-next-line */}
                                                                            <a className="dropdown-item" onClick={() => handleDelete(comments)}>Delete</a>
                                                                        </div>
                                                                        {
                                                                            infoOpen === true && infoMessageId === comments.id ?
                                                                                <div className="dropdown show" style={{
                                                                                    borderRadius: "7px", minWidth: "fit-content", margin: '10px',
                                                                                    border: '1px solid',
                                                                                    padding: '5px',
                                                                                    width: '250px',
                                                                                    maxHeight: '200px',
                                                                                    overflow: 'auto',
                                                                                    float: 'right'

                                                                                }}>
                                                                                    <label style={{
                                                                                        color: 'green'
                                                                                    }}><b>Read By</b><label onClick={() => {
                                                                                        handleInfoClose()
                                                                                    }} style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}><b>X</b></label></label>
                                                                                    {
                                                                                        infoUsers?.map((item => {
                                                                                            return <div><label>{item.username}</label><br /></div>
                                                                                        }))
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        <p style={{ backgroundColor: "#f3f3f3", width: '100%', padding: "12px 10px 12px 14px", borderRadius: "7px", marginTop: '-8px', textAlign: 'left' }}>
                                                                            <div style={{ whiteSpace: 'break-spaces', color: comments.is_deleted === "true" ? 'grey' : 'black' }} dangerouslySetInnerHTML={{ __html: comments.is_deleted === 'true' ? "you deleted this message" : comments.message }}></div>
                                                                            {/* eslint-disable-next-line  */}
                                                                        </p>
                                                                    </li>
                                                                    :
                                                                    //for message display dropdown right side chat box messages
                                                                    <li className="dropdown show" style={{ listStyle: "none", width: '100%' }}>
                                                                        <div className="" style={{
                                                                            margin: '0px', display: 'flex',
                                                                            alignItems: 'normal',
                                                                            justifyContent: 'end'
                                                                        }}>
                                                                            <div className="" style={{ padding: '0px' }}>
                                                                                <p style={{ backgroundColor: "#f3f3f3", padding: "12px 10px 12px 14px", textAlign: 'left', borderRadius: "7px 0px 0px 7px", float: 'right' }}>
                                                                                    <div style={{ whiteSpace: 'break-spaces', color: comments.is_deleted === "true" ? 'grey' : 'black' }} dangerouslySetInnerHTML={{ __html: comments.is_deleted === 'true' ? "you deleted this message" : comments.message }}></div>
                                                                                </p>
                                                                            </div>

                                                                            <div className="" style={{ backgroundColor: "#f3f3f3", marginBottom: '4.5px', padding: '0px', textAlign: 'left', borderRadius: "0px 7px 7px 0px" }}>
                                                                                {
                                                                                    comments.is_deleted === "true" ?
                                                                                        null
                                                                                        :
                                                                                        <>
                                                                                            {/* eslint-disable-next-line  */}
                                                                                          
                                                                                        </>
                                                                                }
                                                                                <div className="dropdown-menu dropdown-menu-left navbar-dropdown" style={{ borderRadius: "7px", minWidth: "fit-content" }} aria-labelledby="dropdownMenuLink">
                                                                                    {/* eslint-disable-next-line   */}
                                                                                    <a className="dropdown-item" onClick={() => handlecom(comments)}>Reply</a>
                                                                                    {/* eslint-disable-next-line */}
                                                                                    <a className="dropdown-item" onClick={() => handleGetInfo(comments)}>Info</a>
                                                                                    {/* eslint-disable-next-line   */}
                                                                                    <a className="dropdown-item" onClick={() => handleDelete(comments)}>Delete</a>
                                                                                </div>
                                                                                {
                                                                                    infoOpen === true && infoMessageId === comments.id ?
                                                                                        <div className="dropdown show" style={{
                                                                                            borderRadius: "7px", minWidth: "fit-content", margin: '10px',
                                                                                            border: '1px solid',
                                                                                            padding: '5px',
                                                                                            width: '250px',
                                                                                            maxHeight: '200px',
                                                                                            overflow: 'auto',
                                                                                            float: 'right'

                                                                                        }}>
                                                                                            <label style={{
                                                                                                color: 'green'
                                                                                            }}><b>Read By</b><label onClick={() => {
                                                                                                handleInfoClose()
                                                                                            }} style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}><b>X</b></label></label>
                                                                                            {
                                                                                                infoUsers?.map((item => {
                                                                                                    return <div><label>{item.username}</label><br /></div>
                                                                                                }))
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>

                                                                        </div>


                                                                    </li>}</p>
                                                }
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                            <div class="modal-footer m-1 justify-content-center" style={{}}>
                                <div className="form-sample col-12">
                                    <form encType="multipart/form-data" method="post" autoComplete="off">
                                        {/* <div className="form-sample col-12"> */}
                                        {/* <label>Resume*</label> */}
                                        {chat === 1 ?
                                            <div><button style={{ backgroundColor: 'transparent', border: '0', float: "right" }} type="button" onClick={() => handleChatClose()} className="d-flex align-items-right" ><i class="mdi mdi-close text-black"></i></button>
                                                <FilePond
                                                    allowFileTypeValidation={true}
                                                    fileValidateTypeLabelExpectedTypes={['allTypes']}
                                                    allowMultiple={true}
                                                    // maxFiles={3}
                                                    maxFiles={1}
                                                    instantUpload={false}
                                                    storeAsFile={true}
                                                    // eslint-disable-next-line
                                                    credits={"", ""}
                                                    onaddfilestart={(file) => {
                                                        handleDoc(file)
                                                        setHideButton(true)
                                                    }}
                                                    // for show uplode icon
                                                    // allowProcess = {false}
                                                    // start for image editor
                                                    allowReorder={true}
                                                    // imageEditInstantEdit = {true}
                                                    onpreparefile={(file, output) => {
                                                        // console.log("prepare file", file.getMetadata());
                                                        // console.log("prepare", output);
                                                        const img = document.createElement("img");
                                                        img.src = URL.createObjectURL(output);
                                                        // document.body.appendChild(img);
                                                    }}
                                                    imageResizeUpscale={false}
                                                    imageResizeMode={"contain"}
                                                    imageEditIconEdit='<svg width="26" height="26" viewBox="0 0 26 26" 
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8.5 17h1.586l7-7L15.5 8.414l-7 7V17zm-1.707-2.707l8-8a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-8 8A1 1 0 0 1 10.5 19h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 .293-.707z"
                                                                   fill="blue" fill-rule="nonzero" stroke="blue" stroke-width="2"></path></svg>'
                                                    imageEditEditor={create({
                                                        onconfirm: () => {
                                                            setHideButton(true)
                                                        },
                                                        cropMinImageWidth: 128,
                                                        cropMinImageHeight: 128
                                                    })}
                                                    // end for image editor
                                                    server={{
                                                        // fake server to simulate loading a 'local' server file and processing a file
                                                        process: (fieldName, file, metadata, load) => {
                                                            // simulates uploading a file
                                                            setTimeout(() => {
                                                                load(Date.now())
                                                                fileHandle(file)
                                                                // handleImageChange(file)
                                                            }, 1000);
                                                        },
                                                        load: (source, load) => {
                                                            // simulates loading a file from the server
                                                            fetch(source).then(res => res.blob()).then(load);
                                                        }
                                                    }}
                                                >

                                                </FilePond>
                                            </div>
                                            : chat === 2 ?
                                                <div>
                                                    <button style={{ backgroundColor: 'transparent', border: '0', float: "right" }} type="button" onClick={() => handleChatClose()} className="d-flex align-items-right" ><i class="mdi mdi-close text-black"></i></button>

                                                    <FilePond
                                                        allowFileTypeValidation={true}
                                                        fileValidateTypeLabelExpectedTypes={['allTypes']}
                                                        allowMultiple={true}
                                                        maxFiles={1}
                                                        instantUpload={false}
                                                        files={[copyfile]}
                                                        storeAsFile={true}
                                                        // eslint-disable-next-line
                                                        credits={"", ""}
                                                        // allowProcess = {false}
                                                        onaddfilestart={(file) => {
                                                            handleDoc(file)
                                                            setHideButton(true)
                                                        }}
                                                        // start for image editor
                                                        allowReorder={true}
                                                        onpreparefile={(file, output) => {
                                                            // console.log("prepare file", file.getMetadata());
                                                            // console.log("prepare", output);
                                                            const img = document.createElement("img");
                                                            img.src = URL.createObjectURL(output);
                                                            // document.body.appendChild(img);
                                                        }}
                                                        imageResizeUpscale={false}
                                                        imageResizeMode={"contain"}
                                                        imageEditIconEdit='<svg width="26" height="26" viewBox="0 0 26 26" 
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8.5 17h1.586l7-7L15.5 8.414l-7 7V17zm-1.707-2.707l8-8a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-8 8A1 1 0 0 1 10.5 19h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 .293-.707z"
                                                                   fill="blue" fill-rule="nonzero" stroke="blue" stroke-width="2"></path></svg>'
                                                        imageEditEditor={create({
                                                            onconfirm: () => {
                                                                setHideButton(true)
                                                            },
                                                            cropMinImageWidth: 128,
                                                            cropMinImageHeight: 128
                                                        })}
                                                        // end for image editor
                                                        server={{
                                                            // fake server to simulate loading a 'local' server file and processing a file
                                                            process: (fieldName, files, metadata, load) => {
                                                                // simulates uploading a file
                                                                setTimeout(() => {
                                                                    load(Date.now())
                                                                    fileHandle(files)
                                                                    // handleImageChange(file) 
                                                                }, 1000);
                                                            },
                                                            load: (files, load) => {
                                                                // simulates loading a file from the server
                                                                fetch(files).then(res => res.blob()).then(load);
                                                            }
                                                        }}
                                                    >
                                                    </FilePond>
                                                </div>
                                                : ""}
                                    </form>
                                    {handleHover &&
                                        <div className=" col-md-9" style={{ backgroundColor: "#eee", borderRadius: '7px', marginBottom: "3px", paddingBottom: "2px" }}>
                                            <button style={{ backgroundColor: 'transparent', border: '0', marginLeft: "89%" }} type="button" onClick={handleMouseOut} className="d-flex align-items-right" data-dismiss="modal"><i className="mdi mdi-close text-black"></i></button>
                                            <p style={{ color: "green", marginLeft: "16px", marginTop: "-29px" }}>{commentDetails.username}</p>
                                            <p style={{ marginLeft: "16px" }}>
                                                {commentDetails.path ?
                                                    commentDetails.path.substr(commentDetails.path.length - 4) === "blob" && commentDetails.message.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                        // eslint-disable-next-line 
                                                        <a download={commentDetails.path} className='text-primary' target="_blank" href={commentDetails.path}>
                                                            <img alt={commentDetails.message} src={commentDetails.path} width="60" height="60" /></a> :
                                                        !commentDetails.path.match(/.(jpg|jpeg|png|gif)$/i) ?
                                                            //  eslint-disable-next-line  
                                                            <a download={commentDetails.message} className='text-primary' target="_blank" href={commentDetails.path}>
                                                                {commentDetails.message}</a> :
                                                            //  eslint-disable-next-line  
                                                            <a download={commentDetails.path} className='text-primary' target="_blank" href={commentDetails.path}>
                                                                <img alt={commentDetails.message} src={commentDetails.path} width="60" height="60" />{commentDetails.message}</a>
                                                    :
                                                    <div style={{ whiteSpace: 'break-spaces', overflow: 'auto', maxHeight: '70px' }} dangerouslySetInnerHTML={{ __html: commentDetails.message }}></div>
                                                }
                                                {/* {commentDetails.message} */}
                                            </p>
                                        </div>
                                    }
                                    {/* </div> */}
                                    {
                                        !hideButtons ?
                                            <>
                                                
<div className="chat-container" style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '10px' }}>
    {/* Textarea Input */}
   

    {/* Icons Section */}
    {!isMobile ?
   ( <div style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
    {/* Attachment Icon */}
    <div title={ATTACH_FILE} onClick={handleChat}>
        <FiPaperclip className="chat-send-icons" size={28} color="#000" />
    </div>

    {/* Video Call Icon */}
    <div>
        <FaVideo className="chat-send-icons" size={28} onClick={sendTaskMeetingUrl} color="#000" />
    </div>

    {/* Send Button */}
    <button
        disabled={state.comment.trim() === ""}
        type="button"
        style={{
            background: 'transparent',
            border: 'none',
            padding: '0px',
            cursor: state.comment.trim() === "" ? "not-allowed" : "pointer"
        }}
        onClick={() => (disable ? undefined : (chat === 1 || chat === 2 ? fileHandle(docFile) : sendMessage()))}
    >
        <FiSend
            className="chat-send-icons"
            size={24}
            color={state.comment.trim() === "" ? "#000" : "#ccc"} // Gray for disabled, Black for enabled
        />
    </button>
</div>):(
    <div className="row" style={{ display: 'flex', width: '100%' }}>
    <div style={{ flex: '1' }}>
        <div style={{}}>
            <textarea
                autoFocus
                className="form-control copyable-text selectable-text"
                ref={textInput}
                id="recipient-name document_attachment_doc"
                style={{
                    maxHeight: '200px',
                    overflowY: 'scroll',
                    borderColor: 'grey',
                    borderRadius: '20px',
                    height: 'auto',
                    padding: '7px',
                    width: '100%', // Equal width for textarea
                }}
                contentEditable="true"
                suppressContentEditableWarning={true}
                placeholder="Type a message"
                onKeyPress={handleKeypress}
                value={state.comment}
                onChange={(event) => {
                    handleInputChange(event)

                }}
                //myPaste={handlePaste}
                onPaste={(e) => myPaste(e)}
            />
        </div>
    </div>
    <div style={{ marginRight: '1%', display: 'flex', alignItems: 'center' }}>
        <div title={ATTACH_FILE} onClick={handleChat} style={{marginLeft:'40%'}}>
            <FiPaperclip className="chat-send-icons" size={28} color='#000' />
        </div>
        <div  style={{marginLeft:'10%'}}>
            <FaVideo className="chat-send-icons" size={28} onClick={sendTaskMeetingUrl} color='#000' />
        </div>
        <div  style={{marginLeft:'20%'}}>
            <button
                disabled={state.comment.trim() === ""}
                type="button"
                style={{ background: 'transparent', border: 'none', padding: '0px', cursor: state.comment.trim() === "" ? "not-allowed" : "pointer" }}
                onClick={() => (disable ? undefined : (chat === 1 || chat === 2 ? fileHandle(docFile) : sendMessage()))}
            >
                <FiSend
                    className='chat-send-icons'
                    size={24}
                    color={state.comment.trim() === "" ? "#ccc" : "#000"} // Gray for disabled, Blue for enabled
                />
            </button>
        </div>
    </div>
</div>
)}

</div>
                                                {
                                                    errorMessage !== null ?
                                                        <label className='text-danger'>{errorMessage}</label>
                                                        :
                                                        null
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </div>
    );


}
