import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReleaseBug from "./components/Support"
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ReleaseBug />} /> 
      </Routes>
    </Router>
  );
}

export default App;
