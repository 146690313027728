import React, { useState } from "react";
import API from '../commonComponents/Common/Network/API';
import axios from "axios";
import Alert from '../commonComponents/Alert';
import { MESSAGE_REFRESH_TIMER, capitalizeFirstLetter, isValidEmail } from '../commonComponents/Common/commonUsage';
import MainTaskChatBox from '../commonComponents/Common/ChatMainTask/index';
import { Server_URL } from "../commonComponents/Common/config";
const APP_NAME = 'KShiksha';

const Support = () => {
  const [dataReport, setData] = useState({
    name_to_contact: '',
    email_to_contact: '',
    bug_one_sen: '',
    mobile_number:'',
    referenceEmail:''
  });

  const [buttonClicked, setButtonClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchWord, setSearchWord] = useState('');
  const [pendingTasks, setPendingTasks] = useState([]);
  const [empId, setEmpId] = useState('0');
  const [open, setOpen] = useState({ status: false })
  const [cardInfo, setCardInfo] = useState()
  const [allMessages, setAllMessages] = useState([])
  const [referenceEmail, setReferenceEmail] = useState(false);
  const handleChange = (e) => {
    setErrorMessage(null);
    setData({
      ...dataReport,
      [e.target.name]: e.target.value
    });
  };

  const sendChat = async (story_id, message, email) => {
    await axios.post(
       `${Server_URL}/user_story_chat.php`, {
      action: "send",
      corp_code: "KShiksha",
      groupId: story_id,
      message: `${message}\n${dataReport.mobile_number}\n${dataReport.referenceEmail?dataReport.referenceEmail:''}`,
      messagedBy: empId,
      msgId: " ",
      groupName: '',
      groupEmail: [email],
      reply_id: "",
    }, {}, false);
    let info = { us_id: story_id, action: 'maintask', id: story_id, project_id: "791d329455b176c6afca53ff2deb9c4d", project_name: 'KShiksha', messagedBy: empId, outsideApp: true, corp: 'KShiksha', empId: empId }
    setCardInfo(info)
    setOpen({ status: true });
  }


  const addMainTaskFromSupportToKanban = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setButtonClicked(true);
    const { email_to_contact, bug_one_sen, name_to_contact,mobile_number } = dataReport;

    if (!email_to_contact || !bug_one_sen || !name_to_contact ||!mobile_number) {
      setButtonClicked(false);
      setErrorMessage('Please fill all mandatory details.');
      return;
    }
    if (!/^\d{10}$/.test(dataReport.mobile_number)) {
      setButtonClicked(false);
      setErrorMessage('Enter a valid 10-digit mobile number.');
      return;
    }
    let sanitizedValue = bug_one_sen.charAt(0).match(/[^a-zA-Z0-9]/) ? 
      (bug_one_sen.length > 1 ? bug_one_sen : "I Need Help") : bug_one_sen;

    try {
      var response = await axios.post(
        `${Server_URL}/manage_userstories.php`, {
        action: "addMainTaskFromOthers",
        title: sanitizedValue,
        description: sanitizedValue,
        email: dataReport.email_to_contact,
        projectId: "791d329455b176c6afca53ff2deb9c4d",
        appName: APP_NAME,
        story_type: 'support_ticket_out'
      }, {}, false);
console.log('tetsing console for addmiaask',response.data)
      if (response.data.status === 'True') {
        sendChat(response.data.story_id, sanitizedValue, response.data.manager_email);
        setEmpId(response.data.emp_id);
        setData({ bug_one_sen: "" });
        setButtonClicked(false);
      } else {
        setButtonClicked(false);
      }
    } catch (error) {
      Alert('error', error.message);
      setButtonClicked(false);
    }
  };

  const addInProject = async (email, name) => {
    const payload = JSON.stringify({
      fullname: name,
      email: email,
      username: email,
      mobile: "1234567890",
      action: "check_user",
      corp: "KShiksha",
      projectId: "791d329455b176c6afca53ff2deb9c4d",
      password: "agile24x7",
      userType: "Limited Access Contributor",
      user_status: "Active",
      created_by: "Support System",
      created_name: "Support System",
      url: "https://wetasker.com/#/",
      appName: "KShiksha",
    });

    try {
      var response = await axios.post(
        `${Server_URL}/manage_user.php`,
        payload, {}, false
      );
      console.log('tetsing console for addinprojectaddmiaask',response)
      if (response.data.status === "True" || response.data.status === "False") {
        setEmpId(response.data.emp_id);
        localStorage.setItem("emp_id", response.data.emp_id);
        getSupportTicketOut(response.data.emp_id);
        console.warn('response.emp_id response.emp_id',response.data.emp_id)
        setButtonClicked(false);
       
      
      }
      if(response.data.status==='True'){ setReferenceEmail(true)
        console.log('reference Email trueeeee or false',referenceEmail)
      }
    } catch (error) {
      console.error("Error adding user to project:", error);
      setButtonClicked(false);
    }
  };

  const getSupportTicketOut = async (emp_id) => {

    console.log("emmmmTesting console for getSupportTicketOut:", emp_id);
    const payload = JSON.stringify({
      crop: "KShiksha",
      empId: emp_id,
      action: "support_ticket_out",
      role: "Limited Access Contributor",
    });
  
    try {
      var response = await axios.post(
        `${Server_URL}/backlogspage.php`,
        payload, {}, false
      );
  
      console.log("Testing console for dddgetSupportTicketOut:", response);
  
      if (response.data.status === "true") {
        setPendingTasks(response.data.data);
        console.log("Pending Tasks:", pendingTasks);
console.log("Type of pendingTasks:", typeof pendingTasks);
console.log("Is Array:", Array.isArray(pendingTasks));

      } else {
        setPendingTasks([]);
      }
    } catch (error) {
      console.error("Error fetching support tickets:", error);
    }
  };
  

  const getSupportEmail = () => {
    setErrorMessage(null);
    if (isValidEmail(dataReport.email_to_contact)) {
 
      addInProject(dataReport.email_to_contact, dataReport.name_to_contact);

    } else {
      setErrorMessage('Enter Valid Email');
    }
  };
  const getMessagesCount = (data, msg, task) => {
  
    const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task.id).map((messages, i) => {
      // eslint-disable-next-line
      return i, messages
    })
    return (
      <i>
        {msgCount.length > 0 ?
          <div style={{ display: 'flex', marginRight: '-8px' }}>
            <img src="images/common/chat.svg" title={'Chat'} alt="logo" style={{ width: '20px', height: '20px' }}
              onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-4px' }}>{msgCount.length > 9 ? "+9" : msgCount.length}</span>
          </div>
          :
          <div style={{ display: 'flex' }}>
            <img src="public/chat.svg" title={'chat'} alt="logo" style={{ width: '20px', height: '20px' }}
              onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
          </div>}
      </i>
    )
  };

    const handleChatOpen = (action, index, sno, assignedTo) => {
    var info;
    setOpen({ status: true, action: action });
    if (action === "maintask") {
      info = { us_id: index.id, action: 'maintask', id: index.id, project_id: "791d329455b176c6afca53ff2deb9c4d", project_name: 'KShiksha', sno: sno, messagedBy: empId, outsideApp: true, corp: 'KShiksha', empId: empId,taskTitle:index.tasktitle,fullName:dataReport.name_to_contact }
    }
    setCardInfo(info);
    setOpen({ status: true });
  }
  const handleClose = () => {
    setOpen({ status: false })
    setData({
      bug_one_sen: "",
      email_to_contact: "",
      steps_for_bug: "",
      name_to_contact: ""
    })
    getSupportTicketOut(empId)
  };

  const handleModalClose = () => {
    setOpen({ status: false });
    getSupportTicketOut(empId)
    setData({
      bug_one_sen: "",
      email_to_contact: "",
      steps_for_bug: "",
      name_to_contact: "",
      mobile_number:""
    })
  }
  return (
    <div className="container mt-1 w-full h-full">
      <div className="accordion">
        <div style={{marginBottom:'2%'}}>
          <img src="/KS.jpeg" width="120" height="80" alt="Kshiksha Logo" />
        </div>

        <div className="card">
          <form onSubmit={addMainTaskFromSupportToKanban}>
            <div className="card-body">
              <h6>Please enter your Name: <span className="text-red-500">*</span></h6>
              <input type="text" value={dataReport.name_to_contact} onChange={handleChange} className="form-control border-b border-black bg-transparent" name="name_to_contact" placeholder="Enter name" required />
            </div>

            <div className="card-body">
              <h6>Email id for us to reach you <span className="text-red-500">*</span></h6>
              <input type="email" onBlur={getSupportEmail} value={dataReport.email_to_contact} onChange={handleChange} className="form-control border-b border-black bg-transparent" name="email_to_contact" placeholder="Enter email" required />
            </div>
            <div className="card-body">
              <h6>Email Mobile Number <span className="text-red-500">*</span></h6>
              <input type="mobile"  value={dataReport.mobile_number} onChange={handleChange} className="form-control border-b border-black bg-transparent" name="mobile_number" placeholder="Enter Mobile Number" required />
            </div>
            {referenceEmail&&(  <div className="card-body">
              <h6>Reference Email <span className="text-red-500">*</span></h6>
              <input type="email"  value={dataReport.referenceEmail} onChange={handleChange} className="form-control border-b border-black bg-transparent" name="referenceEmail" placeholder="Enter Reference Email" required />
            </div>)}

            <div className="card-body">
              <h6>Tell us about You: <span className="text-red-500">*</span></h6>
              <div className="flex items-center">
                <input type="text" onChange={handleChange} className="form-control border-b border-black bg-transparent w-10/12" name="bug_one_sen" value={dataReport.bug_one_sen} placeholder="Ex:Jhon Doe,System Engineer" />
                <button
                  type="submit"
                  disabled={buttonClicked}
                  className="border-none"
                  style={{ border: "none", background: "transparent", cursor: "pointer",marginLeft:'75%' }}
                >
                  <img src="/chat.svg" alt="logo" width="100" height="60" />
                  <h6 style={{color:'#000'}}>Let’s Chat</h6>
                </button>

              </div>
              {errorMessage && <div className="text-red-500 text-sm">{errorMessage}</div>}
            </div>
          </form>
        </div>

        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
  <table>
    <tbody>
      {pendingTasks.length > 0 ? pendingTasks.filter((val) => {
        if (searchWord === "") {
          return val;
        } else if (val.tasktitle?.toLowerCase().includes(searchWord.toLowerCase()) || 
                   val.id?.toLowerCase().includes(searchWord.toLowerCase()) || 
                   val.completeStatus?.toLowerCase().includes(searchWord.toLowerCase())) {
          return val;
        }
        return null;
      }).map((todo, index) => (
        <tr key={index}>
          <td style={{ border: '1px solid #e1e1e1' }}>
            <div className="col-12 td1">
              <div className="col-12">
                <div className="d-flex col-12">
                  <div className="p-2" style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                    <b style={{ cursor: 'pointer' }}>
                      <p style={{ color: 'black', textTransform: 'capitalize' }}>
                        AG{'-'}{todo.id}{'-'}{todo.tasktitle}
                      </p>
                    </b>
                  </div>
                  <div style={{
                    width: '20%',
                    backgroundColor: todo.completeStatus === 'pending' ? '#e8e35f' : '#6fe2ac',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 5,
                    padding: '3px',
                    marginTop: 5,
                    marginBottom: 5,
                    textAlign: 'center',
                    height: 'fit-content'
                  }}>
                    {capitalizeFirstLetter(todo.completeStatus)}
                  </div>
                  <div style={{ width: '10%', padding: '5px', marginLeft: '5px', marginTop: '2px', textAlign: "end" }}>
                                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                          {
                                            getMessagesCount(index, allMessages, todo)
                                          }
                                        </button>
                                      </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )) : <tr><td></td></tr>}
    </tbody>
  </table>
</div>

      </div>
      {open.status && (
  <>
    {console.log('open', open.status)} 
    <MainTaskChatBox 
      open={open.status} 
      handleClose={handleClose} 
      data={cardInfo} 
      handleModalClose={handleModalClose} 
    />
  </>
)}

    </div>
  );
};

export default Support;



