import moment from "moment-timezone";  // ✅ Correct import

export default function convertPSTtoLocalTime(time) {
    if (!time) {
        return '';
    }

    // Local time zone (system timezone)
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the input time to PST timezone
    const pstMoment = moment.tz(time, 'America/Los_Angeles'); 

    // Convert the PST time to the local time zone
    const localTime = pstMoment.clone().tz(localTimeZone);
console.log('localtime',localTime)
    // Format the local time as "MM/DD/YYYY hh:mm:ss A z"
    return localTime.format("MM/DD/YYYY hh:mm:ss A");
}
