//release environment (development)
// export const Server_URL = 'https://api-single.agile24x7.com/task_pg_release';
// export const Reports_URL = 'https://pgtestreporting.agile24x7.com';
// export const Client_URL = 'https://test.wetasker.com/#/';
// export const userIPInfoURL = 'https://ipapi.co/json';
// export const Meet_URL = 'rally.wetasker.com';
// export const ChatBot_URL = "https://testchatbot.agile24x7.com"
// export const ONE_SIGNAL_ID = '6323dec8-20d5-433e-a75c-00734e5f583f'

// preprod environment (preprod)
// export const Server_URL = 'https://api-single.agile24x7.com/task24x7_preprod';
// export const Reports_URL = 'https://pgreportingpreprod.agile24x7.com';
// export const Client_URL = 'https://preprod-wetasker.task24x7.com/#/';
// export const userIPInfoURL= 'https://ipapi.co/json';
// export const Meet_URL = 'rally.wetasker.com';
// export const ChatBot_URL = "https://chatbot.agile24x7.com"
// export const ONE_SIGNAL_ID = '1f4d66d5-4c90-465e-907a-13045ccac5c4'

//production envrironment (production)
export const Server_URL = 'https://api-single.wetasker.com/task_pg_prod';
export const Reports_URL = 'https://pgreporting.wetasker.com';
export const Client_URL = 'https://wetasker.com/#/';
export const userIPInfoURL = 'https://ipapi.co/json';
export const Meet_URL = 'rally.wetasker.com';
export const ChatBot_URL = "https://chatbot.wetasker.com"
export const ONE_SIGNAL_ID = '01df9ab9-9ed3-4e43-9eed-09795eb45570'
